.section {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.head2 {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.inputClass {
    margin: 0 !important;
    margin-bottom: 12px !important;
    padding: 6px;
    border: 0 !important;
    border-bottom: 1px solid var(--black110) !important;
    background: transparent !important;
    border-radius: 0 !important;
}

.nestedInputClass {
    color: var(--black) !important;
    background: transparent;
    border-bottom: 1px solid var(--black110);
}

.head1 {
    font-weight: 500;
    font-size: var(--h5-font-size);
    line-height: 19px;
    color: #212121;
}

.card {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    margin-top: 24px;
    margin-bottom: 20px;
}

.shopImg {
    height: 76px;
    width: 76px;
    border-radius: 50%;
    margin-right: 16px;
}

.shopName {
    font-weight: 500;
    font-size: var(--h5-font-size);
    color: #000000;
    padding-bottom: 12px;
}

.email {
    font-weight: 500;
    font-size: var(--m-font-size);
    color: #000000;
    opacity: 0.5;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.form {
    width: 60%;
    padding: 20px;
    background: #F8F8F8;
    margin-top: 16px;

    @media (--phone) {
        width: 90%;
    }

    textarea {
        font-size: var(--m-font-size) !important;
    }

    input {
        font-family: inherit !important;
    }

    textarea, input {
        font-weight: var(--medium-bold) !important;
        color: var(--black80) !important;
    }

    input::placeholder, textarea::placeholder {
        color: var(--black50) !important;
        opacity: 1 !important;
        font-weight: var(--medium-bold) !important;
        font-size: var(--m-font-size) !important;
    }
}

.errClass {
    padding-bottom: 8px;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.right {
    margin-left: auto;
    margin-right: 0px;
}
