.head {
    font-size: var(--h5-font-size);
    font-weight: var(--medium-bold);
    padding-top: 30px;
    padding-bottom: 20px;
    color: var(--black1);
    text-align: center;
}

.minimal {
    margin-top: var(--l-spacing);
    .head {
        display: none;
    }

    .follow {
        justify-content: flex-start;

        img {
            width: 28px;
            height: 28px;
        }
    }
}

.menu {

    display: flex;
    align-items: center;
    justify-content: center;
    .head {
        display: none;
    }

    .follow {
        justify-content: flex-start;

        margin-top: var(--xl-spacing);
        margin-bottom: 0;

        .card {
            img {
                width: 22px;
                height: 22px;
            }

            padding-left: 0 !important;
            padding-right: var(--l-spacing) !important;

            &:last-child {
                padding-right: 0 !important;
            }
        }
    }
}

.head1 {
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold);
    padding-top: 30px;
    color: var(--black50);
    text-align: center;
}

.follow {
    display: flex;
    justify-content: center;
}

.start{
    display: flex;
    justify-content: flex-start !important;
    margin-bottom: var(--xl-spacing);
}

.card {
    padding: 0 10px !important;
}


.card1 {
    padding-right: 20px;
    padding-top: 20px;
}

.partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.termsAndPolicy {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--l-spacing);
}

.seperator {
    padding: 0 var(--m-spacing);
}

.link {
    color: var(--black60) !important;
    text-decoration: underline;
}
