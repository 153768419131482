.stickyBottom{
    display: flex;
    height: 52px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: fixed;
    background:var(--primary5);
    z-index: 99999 !important;
    @media (--desktop) {
        display:none;
    }
}

.div1{
    min-width: 50%;
    padding:18px 0px;
    font-weight: var(--bold);
    font-size: var(--m-font-size);
    color: var(--black);
    background: var(--grey4);
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.div2{
    flex-grow: 1;
    padding: 18px 0;
    text-align: center;
    background:var(--primary) !important;
    color:var(--white) !important;
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold) !important;
    align-items: center;
}


.line2{
    margin:0px 12px;
}

.padding {
    @media (--phone) {
        padding-top:60px;
    }
}