.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    width: 100%;
}

.section {
    width: 100%;
    border-bottom: 1px solid var(--black110);
    padding: 16px 0px;
}

.left {
    display: flex;
    align-items: center;
    font-size: var(--h5-font-size) !important;
    font-weight: var(--semi-bold);
    color: var(--black60);
}

.left2 {
    display: flex;
    align-items: center;
    font-size: var(--h5-font-size) !important;
    font-weight: var(--semi-bold);
    color: var(--primary);
}

.icon {
    padding-right: var(--m-spacing);
}

.body {
    padding: 0 var(--xl-spacing);
    @media (--desktop) {
        padding: 2% 15%;
    }
}

.item {
    padding-bottom: var(--m-spacing);
    font-size: var(--m-spacing);
    color: var(--black60);
    display: flex;
    justify-content: flex-start;
    @media (--desktop) {
        background: #F8F8F8;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
    }
}

.time {
    text-transform: lowercase;
    color: var(--green);
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    @media (--desktop) {
        margin-left: 0%;
    }
}

.timeContainer {
    @media (--desktop) {

    }
}

.day {
    text-transform: capitalize;
}

.right{
    margin-right: 2%;
    @media (--desktop){
        margin-right: 6px;
    }
}
