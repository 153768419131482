.mainContainer {
    color: var(--black60);
    margin-top: 30px;
    border-bottom: 1px solid var(--black110);
}

.margin {
    margin-top: var(--h2-font-size);
    margin-bottom: var(--h3-font-size);
    color: var(--black110);
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    text-align: center;
}

.preview {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    @media (--desktop) {
        display: flex;
        
    }
}

.spacer {
    margin-bottom: var(--h2-font-size);
    font-weight: var(--medium-bold);
    font-size: var(--h3-font-size);
    color: var(--primary);
    display: flex;
    justify-content: center;
}

.flex {
    display: flex;
    align-items: center;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--d-font-size);
    padding: 10px 0px;

    @media (--desktop) {
        justify-content: center;
        padding-left: var(--xxxl-spacing)
    }

    @media (--phone) {
        text-align: left;
    }
}

.flex1 {
    display: flex;
    margin-bottom: var(--h2-font-size);
    align-items: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: var(--black);
    font-size: var(--d-font-size);
    @media (--desktop) {
        justify-content: center;
        flex-direction: column;
    }
}

.tile {
    margin-left: var(--h3-font-size);
    text-align: start;
    @media (--desktop) {
        display: flex;
        flex-direction: column;
    }
}

.tile1 {
    margin-left: var(--h3-font-size);
    text-align: start;
    @media (--desktop) {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }
}

.tile2 {
    margin-left: var(--h3-font-size);
    text-align: start;
    align-items: flex-start;
}

.grey {
    background-color: #FAFAFA;
    padding: var(--h2-font-size) 0px var(--m-spacing);

    div {
        @media (--desktop) {
            display: flex;
            text-align: center;
            margin-left: var(--xxs-spacing);
        }
    }
}

.text {
    text-align: left;
    font-weight: 500;
    color: var(--black80);
}


.marginTop {
    margin-top: var(--m-font-size);
    text-align: left;
    font-weight: normal;
    color: var(--black60);
}

.box {
    display: flex;
    padding:16px 0;
    @media (--desktop) {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media (--phone) {
        align-items: center;
    }
}

.desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 16px;
    @media (--desktop) {
        margin-left: 0;
        width: 100%;
        margin-top: var(--xs-spacing);
        color: var(--black60);
        align-items: center;
    }

}


.containerDesk {
    @media (--desktop) {
        display: flex;
    }
}

.shipDesk svg {
    @media (--desktop) {
        height: 42px;
        width: 62px;
        margin-bottom: 25px;
    }
}

.payDesk svg {
    @media (--desktop) {
        height: 42px;
        width: 42px;
        margin-bottom: 25px;
    }
}

.orderDesk svg {
    @media (--desktop) {
        height: 42px;
        width: 42px;
        margin-bottom: 25px;
    }
}

.shipDesk, .orderDesk, .payDesk {

    @media (--desktop) {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }

    @media (--phone) {
        width: 32px;
    }
}

.text {
    @media (--desktop) {

    }
}

.lineV {
    @media (--desktop) {
        width: 1px;
        height: 100px;
        background: var(--black30);
    }
}

.lineV1 {
    @media (--desktop) {
        width: 1px;
        height: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        background: var(--black30);
        position: relative;
    }
}

.storeButtons {
    @media (--phone) {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}
