.section {
    display: flex;
    padding:18px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--black110);
}

.head {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.head2 {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.icon {
    margin-left: auto;
    margin-right: 0;
}

.body{
    margin-top:10px;
    font-size: var(--d-font-size);
    color: var(--black70);
    max-width: 100%;
    word-break: break-word;
    text-align: justify;
    @media (--desktop) {
        margin-top: 20px;
        width: 100%;
    }
}

.partners {
    margin-top: var(--xl-spacing);
    margin-bottom: var(--xl-spacing);
    @media (--desktop) {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}

.icon1 {
    padding-right: var(--m-spacing);
}
