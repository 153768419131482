.section {
    display: flex;
    padding:18px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--black110);
}

.head {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.head2 {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}


.icon {
    margin-left: auto;
    margin-right: 0px;
}

.body{
    margin-top:10px;
    text-align: left;
    font-size: var(--d-font-size);
    color: var(--black70);
}
