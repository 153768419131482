.AboutUs_section__QvlMb {
    display: flex;
    padding:18px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--black110);
}

.AboutUs_head__cfilO {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.AboutUs_head2__4YRL8 {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.AboutUs_icon__y2vrv {
    margin-left: auto;
    margin-right: 0;
}

.AboutUs_body__zWP9U{
    margin-top:10px;
    font-size: var(--d-font-size);
    color: var(--black70);
    max-width: 100%;
    word-break: break-word;
    text-align: justify
}

@media screen and (min-width: 420px) {

.AboutUs_body__zWP9U{
        margin-top: 20px;
        width: 100%
}
    }

.AboutUs_partners__CTRhI {
    margin-top: var(--xl-spacing);
    margin-bottom: var(--xl-spacing)
}

@media screen and (min-width: 420px) {

.AboutUs_partners__CTRhI {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center
}
    }

.AboutUs_icon1__7zgR6 {
    padding-right: var(--m-spacing);
}

.Terms_section__H9xVF {
    display: flex;
    padding:18px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--black110);
}

.Terms_head__YeT8m {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.Terms_head2__ELOBO {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}


.Terms_icon__UV9CY {
    margin-left: auto;
    margin-right: 0px;
}

.Terms_body__7xynK{
    margin-top:10px;
    text-align: left;
    font-size: var(--d-font-size);
    color: var(--black70);
}

.StoreTimings_head__S2n7c {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    width: 100%;
}

.StoreTimings_section__deFBg {
    width: 100%;
    border-bottom: 1px solid var(--black110);
    padding: 16px 0px;
}

.StoreTimings_left__HhAYu {
    display: flex;
    align-items: center;
    font-size: var(--h5-font-size) !important;
    font-weight: var(--semi-bold);
    color: var(--black60);
}

.StoreTimings_left2__9swxI {
    display: flex;
    align-items: center;
    font-size: var(--h5-font-size) !important;
    font-weight: var(--semi-bold);
    color: var(--primary);
}

.StoreTimings_icon__vQGWC {
    padding-right: var(--m-spacing);
}

.StoreTimings_body__Lf4GM {
    padding: 0 var(--xl-spacing)
}

@media screen and (min-width: 420px) {

.StoreTimings_body__Lf4GM {
        padding: 2% 15%
}
    }

.StoreTimings_item__FR2Ma {
    padding-bottom: var(--m-spacing);
    font-size: var(--m-spacing);
    color: var(--black60);
    display: flex;
    justify-content: flex-start
}

@media screen and (min-width: 420px) {

.StoreTimings_item__FR2Ma {
        background: #F8F8F8;
        border-radius: 5px;
        display: flex;
        justify-content: space-around
}
    }

.StoreTimings_time__Xeu8a {
    text-transform: lowercase;
    color: var(--green);
    margin-left: auto;
    display: flex;
    justify-content: flex-start
}

@media screen and (min-width: 420px) {

.StoreTimings_time__Xeu8a {
        margin-left: 0%
}

.StoreTimings_timeContainer__ZpHiS {
}
    }

.StoreTimings_day__BLZDa {
    text-transform: capitalize;
}

.StoreTimings_right__h7j6e{
    margin-right: 2%
}

@media screen and (min-width: 420px){

.StoreTimings_right__h7j6e{
        margin-right: 6px
}
    }

.Payment_section__aDXxk {
    display: flex;
    padding:18px 0px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--black110);
}

.Payment_head__71826 {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.Payment_head2__N7rIL {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.Payment_icon__UliRY {
    margin-left: auto;
    margin-right: 0px;
}

.Payment_body__194hT{
    margin-top:10px;
    text-align: left;
    font-size: var(--d-font-size);
    color: var(--black70);
}

.Payment_flex__r4a4p{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Payment_margin__Ef8Km{
    margin-right: 18px;
}

.Payment_grid__T81QL{
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    padding:10px 0px;
}

.Payment_partners__psMt4{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;
}

.Payment_bank__20HTz{
    display: flex;
    align-items: center;
}

.Payment_card1__2sUqT{
    width: 65px;
}
.ContactUs_section__BZpom {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContactUs_head__aabjy {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.ContactUs_head2__m5kyb {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.ContactUs_inputClass__E6TiL {
    margin: 0 !important;
    margin-bottom: 12px !important;
    padding: 6px;
    border: 0 !important;
    border-bottom: 1px solid var(--black110) !important;
    background: transparent !important;
    border-radius: 0 !important;
}

.ContactUs_nestedInputClass__tZvJi {
    color: var(--black) !important;
    background: transparent;
    border-bottom: 1px solid var(--black110);
}

.ContactUs_head1__5rATr {
    font-weight: 500;
    font-size: var(--h5-font-size);
    line-height: 19px;
    color: #212121;
}

.ContactUs_card__cTRDm {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    margin-top: 24px;
    margin-bottom: 20px;
}

.ContactUs_shopImg__UnxvZ {
    height: 76px;
    width: 76px;
    border-radius: 50%;
    margin-right: 16px;
}

.ContactUs_shopName__fXEzA {
    font-weight: 500;
    font-size: var(--h5-font-size);
    color: #000000;
    padding-bottom: 12px;
}

.ContactUs_email__bKfhT {
    font-weight: 500;
    font-size: var(--m-font-size);
    color: #000000;
    opacity: 0.5;
}

.ContactUs_vertical___Ej6L {
    display: flex;
    flex-direction: column;
}

.ContactUs_form__7avgc {
    width: 60%;
    padding: 20px;
    background: #F8F8F8;
    margin-top: 16px
}

@media screen and (max-width: 419px) {

.ContactUs_form__7avgc {
        width: 90%
}
    }

.ContactUs_form__7avgc textarea {
        font-size: var(--m-font-size) !important;
    }

.ContactUs_form__7avgc input {
        font-family: inherit !important;
    }

.ContactUs_form__7avgc textarea, .ContactUs_form__7avgc input {
        font-weight: var(--medium-bold) !important;
        color: var(--black80) !important;
    }

.ContactUs_form__7avgc input::-moz-placeholder, .ContactUs_form__7avgc textarea::-moz-placeholder {
        color: var(--black50) !important;
        opacity: 1 !important;
        font-weight: var(--medium-bold) !important;
        font-size: var(--m-font-size) !important;
    }

.ContactUs_form__7avgc input:-ms-input-placeholder, .ContactUs_form__7avgc textarea:-ms-input-placeholder {
        color: var(--black50) !important;
        opacity: 1 !important;
        font-weight: var(--medium-bold) !important;
        font-size: var(--m-font-size) !important;
    }

.ContactUs_form__7avgc input::placeholder, .ContactUs_form__7avgc textarea::placeholder {
        color: var(--black50) !important;
        opacity: 1 !important;
        font-weight: var(--medium-bold) !important;
        font-size: var(--m-font-size) !important;
    }

.ContactUs_errClass___8bSG {
    padding-bottom: 8px;
}

.ContactUs_flexCenter__pj00y {
    display: flex;
    justify-content: center;
}

.ContactUs_right__21mXN {
    margin-left: auto;
    margin-right: 0px;
}

.StoreInfo_mainContainer__KQWbP {
    color: var(--black60);
    margin-top: 30px;
    border-bottom: 1px solid var(--black110);
}

.StoreInfo_margin__KKbca {
    margin-top: var(--h2-font-size);
    margin-bottom: var(--h3-font-size);
    color: var(--black110);
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    text-align: center;
}

.StoreInfo_preview__p0zFY {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center
}

@media screen and (min-width: 420px) {

.StoreInfo_preview__p0zFY {
        display: flex
}
        
    }

.StoreInfo_spacer__Zc63_ {
    margin-bottom: var(--h2-font-size);
    font-weight: var(--medium-bold);
    font-size: var(--h3-font-size);
    color: var(--primary);
    display: flex;
    justify-content: center;
}

.StoreInfo_flex__Z3WAI {
    display: flex;
    align-items: center;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--d-font-size);
    padding: 10px 0px
}

@media screen and (min-width: 420px) {

.StoreInfo_flex__Z3WAI {
        justify-content: center;
        padding-left: var(--xxxl-spacing)
}
    }

@media screen and (max-width: 419px) {

.StoreInfo_flex__Z3WAI {
        text-align: left
}
    }

.StoreInfo_flex1__CoP7t {
    display: flex;
    margin-bottom: var(--h2-font-size);
    align-items: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: var(--black);
    font-size: var(--d-font-size)
}

@media screen and (min-width: 420px) {

.StoreInfo_flex1__CoP7t {
        justify-content: center;
        flex-direction: column
}
    }

[dir="ltr"] .StoreInfo_tile__i63t2 {
    text-align: left;
}

[dir="rtl"] .StoreInfo_tile__i63t2 {
    text-align: right;
}

.StoreInfo_tile__i63t2 {
    margin-left: var(--h3-font-size)
}

@media screen and (min-width: 420px) {

.StoreInfo_tile__i63t2 {
        display: flex;
        flex-direction: column
}
    }

[dir="ltr"] .StoreInfo_tile1__6HbPg {
    text-align: left;
}

[dir="rtl"] .StoreInfo_tile1__6HbPg {
    text-align: right;
}

.StoreInfo_tile1__6HbPg {
    margin-left: var(--h3-font-size)
}

@media screen and (min-width: 420px) {

.StoreInfo_tile1__6HbPg {
        display: flex;
        flex-direction: column;
        margin-left: 0px
}
    }

[dir="ltr"] .StoreInfo_tile2__veJqP {
    text-align: left;
}

[dir="rtl"] .StoreInfo_tile2__veJqP {
    text-align: right;
}

.StoreInfo_tile2__veJqP {
    margin-left: var(--h3-font-size);
    align-items: flex-start;
}

.StoreInfo_grey__vDWoR {
    background-color: #FAFAFA;
    padding: var(--h2-font-size) 0px var(--m-spacing);
}

@media screen and (min-width: 420px) {

    .StoreInfo_grey__vDWoR div {
            display: flex;
            text-align: center;
            margin-left: var(--xxs-spacing)
    }
        }

.StoreInfo_text__k7fRI {
    text-align: left;
    font-weight: 500;
    color: var(--black80);
}


.StoreInfo_marginTop__TUuHX {
    margin-top: var(--m-font-size);
    text-align: left;
    font-weight: normal;
    color: var(--black60);
}

.StoreInfo_box__4F5eE {
    display: flex;
    padding:16px 0
}

@media screen and (min-width: 420px) {

.StoreInfo_box__4F5eE {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
}
    }

@media screen and (max-width: 419px) {

.StoreInfo_box__4F5eE {
        align-items: center
}
    }

.StoreInfo_desc__7V8IX {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 16px

}

@media screen and (min-width: 420px) {

.StoreInfo_desc__7V8IX {
        margin-left: 0;
        width: 100%;
        margin-top: var(--xs-spacing);
        color: var(--black60);
        align-items: center

}


.StoreInfo_containerDesk__WJ_Yp {
        display: flex
}

.StoreInfo_shipDesk__IaWNr svg {
        height: 42px;
        width: 62px;
        margin-bottom: 25px
}

.StoreInfo_payDesk__wOvh7 svg {
        height: 42px;
        width: 42px;
        margin-bottom: 25px
}

.StoreInfo_orderDesk__wkpef svg {
        height: 42px;
        width: 42px;
        margin-bottom: 25px
}

.StoreInfo_shipDesk__IaWNr, .StoreInfo_orderDesk__wkpef, .StoreInfo_payDesk__wOvh7 {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center
}
    }

@media screen and (max-width: 419px) {

.StoreInfo_shipDesk__IaWNr, .StoreInfo_orderDesk__wkpef, .StoreInfo_payDesk__wOvh7 {
        width: 32px
}
    }

@media screen and (min-width: 420px) {

.StoreInfo_text__k7fRI {
}

.StoreInfo_lineV__jXGtl {
        width: 1px;
        height: 100px;
        background: var(--black30)
}

.StoreInfo_lineV1__8sxKd {
        width: 1px;
        height: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        background: var(--black30);
        position: relative
}

    }

@media screen and (max-width: 419px) {

.StoreInfo_storeButtons__zzcT_ {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center
}
    }

.createdUsing_appSection___xyFj {
    height: 40px;
    width: 100%;
    margin-top: var(--l-spacing);
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px 15px 0 0
}

@media screen and (min-width: 420px) {

.createdUsing_appSection___xyFj {
        width: 100%;
        border-radius: 0
}
    }

.createdUsing_text__iQoVS {
    color: var(--black);
    font-weight: 500;
    font-size: var(--d-font-size);
    padding-right: 12px;
}

.createdUsing_border__IS1ur {
    height: 18px;
    position: relative;
    top: 2px;
}

.createdUsing_border1__D_7Ct {
    padding: 0px 8px;
    height: 18px;
    position: relative;
    top: 6px;
}

.createdUsing_text2__HSUtq{
    font-weight: var(--medium-bold);
    color: var(--black60);
    text-align: center;
}
.CheckoutFooter_stickyBottom__hIJVM{
    display: flex;
    height: 52px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: fixed;
    background:var(--primary5);
    z-index: 99999 !important
}

@media screen and (min-width: 420px) {

.CheckoutFooter_stickyBottom__hIJVM{
        display:none
}
    }

.CheckoutFooter_div1__e8By4{
    min-width: 50%;
    padding:18px 0px;
    font-weight: var(--bold);
    font-size: var(--m-font-size);
    color: var(--black);
    background: var(--grey4);
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.CheckoutFooter_div2__fnXMH{
    flex-grow: 1;
    padding: 18px 0;
    text-align: center;
    background:var(--primary) !important;
    color:var(--white) !important;
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold) !important;
    align-items: center;
}


.CheckoutFooter_line2__3bn4e{
    margin:0px 12px;
}

@media screen and (max-width: 419px) {

.CheckoutFooter_padding__XFBDH {
        padding-top:60px
}
    }
.Testimonials_section__3MqpL {
    width: 100%;
    border-bottom: 1px solid var(--black110);
    padding: var(--l-spacing) 0;
}

.Testimonials_icon__UhoV0 {
    margin-left: auto;
    margin-right: 0px;
}

.Testimonials_head__W1arw {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.Testimonials_head2__SJx8f {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.Testimonials_testimonialContainer__UkC7D {
    border: 1px solid rgba(33, 33, 33, 0.06);
    border-radius: var(--m-border-radius);
    padding: var(--l-spacing) var(--l-spacing) var(--l-spacing) var(--l-spacing);
    max-width: 400px;
    min-height: 270px
}

@mediascreen and (max-width: 419px) {

.Testimonials_testimonialContainer__UkC7D {
        border: 1px solid rgba(33, 33, 33, 0.06);
        border-radius: var(--m-border-radius);
        padding: var(--l-spacing) var(--l-spacing) var(--l-spacing) var(--l-spacing);
        max-width: 0 !important;
        min-height: 0 !important
}
    }

.Testimonials_section1__ARL7J {
    display: flex;
    justify-content: space-between;
}

.Testimonials_profilePic__TuOlY {
    border-radius: 50%;
    width: 56px;
    height: 56px;
}


.Testimonials_name__I1Swg {
    margin-top: var(--l-spacing);
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
}

.Testimonials_des__shuD7 {
    font-size: var(--m-font-size);
    color: var(--black60);
}

.Testimonials_location__zc0kc {
    margin-top: var(--m-spacing);
    font-size: var(--m-font-size);
    display: flex;
    align-items: center;
}

.Testimonials_message__Jyiok {
    margin-top: var(--l-spacing);
    color: var(--black60);
    word-break: break-all;
    padding-right: var(--xs-spacing);
}

.Testimonials_locationName__rqF1T {
    margin-left: var(--s-spacing);
}

.Testimonials_testimonialImage__tJhfi {
    width: 50%;
    height: 50%;
    border-radius: var(--border-radius)
}

@media screen and (max-width: 419px) {

.Testimonials_testimonialImage__tJhfi {
        width: 100% !important;
        height: 100% !important;
        z-index: -999999 !important
}
    }

.Testimonials_imageCon__OyFkL {
    margin-top: var(--l-spacing)
}

@media screen and (min-width: 420px) {

.Testimonials_imageCon__OyFkL {
        display: flex;
        justify-content: center
}
    }


    @media screen and (max-width: 419px) {

.Footer_footer__cWBQd {
        padding: 0 16px
}
    }

.Footer_seoLinks___Uwd0 {
    display:none;
}

.Alert_paper__EIc3U {
    padding: 20px
}

@media screen and (min-width: 420px) {

.Alert_paper__EIc3U {
        max-width:368px !important;
        padding:20px 40px
}
    }

.Alert_modalBtn__Z8CQ5 {
    min-width: 48%;
}

.Alert_title__mC8In {
    font-weight: 500;
    font-size: var(--d-font-size);
    color: rgba(128, 128, 128, 0.75);
}

.Alert_buttons__F0KMK {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: var(--xxl-spacing);
}

.FollowUsAndMore_head__xGUBP {
    font-size: var(--h5-font-size);
    font-weight: var(--medium-bold);
    padding-top: 30px;
    padding-bottom: 20px;
    color: var(--black1);
    text-align: center;
}

.FollowUsAndMore_minimal__fUq9_ {
    margin-top: var(--l-spacing);
}

.FollowUsAndMore_minimal__fUq9_ .FollowUsAndMore_head__xGUBP {
        display: none;
    }

.FollowUsAndMore_minimal__fUq9_ .FollowUsAndMore_follow__YHB14 {
        justify-content: flex-start;
    }

.FollowUsAndMore_minimal__fUq9_ .FollowUsAndMore_follow__YHB14 img {
            width: 28px;
            height: 28px;
        }

.FollowUsAndMore_menu__vBcDK {

    display: flex;
    align-items: center;
    justify-content: center;
}

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_head__xGUBP {
        display: none;
    }

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 {
        justify-content: flex-start;

        margin-top: var(--xl-spacing);
        margin-bottom: 0;
    }

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 .FollowUsAndMore_card__s0tIy img {
                width: 22px;
                height: 22px;
            }

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 .FollowUsAndMore_card__s0tIy {

            padding-left: 0 !important;
            padding-right: var(--l-spacing) !important;
}

.FollowUsAndMore_menu__vBcDK .FollowUsAndMore_follow__YHB14 .FollowUsAndMore_card__s0tIy:last-child {
                padding-right: 0 !important;
            }

.FollowUsAndMore_head1__hpnuM {
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold);
    padding-top: 30px;
    color: var(--black50);
    text-align: center;
}

.FollowUsAndMore_follow__YHB14 {
    display: flex;
    justify-content: center;
}

.FollowUsAndMore_start__J6ZzI{
    display: flex;
    justify-content: flex-start !important;
    margin-bottom: var(--xl-spacing);
}

.FollowUsAndMore_card__s0tIy {
    padding: 0 10px !important;
}


.FollowUsAndMore_card1__X5v1t {
    padding-right: 20px;
    padding-top: 20px;
}

.FollowUsAndMore_partners___jRb8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.FollowUsAndMore_termsAndPolicy__LMVJk {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--l-spacing);
}

.FollowUsAndMore_seperator__xKB9A {
    padding: 0 var(--m-spacing);
}

.FollowUsAndMore_link__W5TXa {
    color: var(--black60) !important;
    text-decoration: underline;
}

.Container_container__yNtYt {
    max-width: 1024px;
    margin: 0 auto;
}

.BorderBottom_border__hFR41 {
    border-bottom: 1px solid var(--black15);
}

