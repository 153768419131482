.appSection {
    height: 40px;
    width: 100%;
    margin-top: var(--l-spacing);
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px 15px 0 0;
    @media (--desktop) {
        width: 100%;
        border-radius: 0;
    }
}

.text {
    color: var(--black);
    font-weight: 500;
    font-size: var(--d-font-size);
    padding-right: 12px;
}

.border {
    height: 18px;
    position: relative;
    top: 2px;
}

.border1 {
    padding: 0px 8px;
    height: 18px;
    position: relative;
    top: 6px;
}

.text2{
    font-weight: var(--medium-bold);
    color: var(--black60);
    text-align: center;
}