.section {
    width: 100%;
    border-bottom: 1px solid var(--black110);
    padding: var(--l-spacing) 0;
}

.icon {
    margin-left: auto;
    margin-right: 0px;
}

.head {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--primary) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.head2 {
    font-weight: var(--semi-bold);
    font-size: var(--h5-font-size) !important;
    color: var(--black60) !important;
    padding-right: 6px !important;
    display: flex;
    width: 100%;
}

.testimonialContainer {
    border: 1px solid rgba(33, 33, 33, 0.06);
    border-radius: var(--m-border-radius);
    padding: var(--l-spacing) var(--l-spacing) var(--l-spacing) var(--l-spacing);
    max-width: 400px;
    min-height: 270px;

    @media(--phone) {
        border: 1px solid rgba(33, 33, 33, 0.06);
        border-radius: var(--m-border-radius);
        padding: var(--l-spacing) var(--l-spacing) var(--l-spacing) var(--l-spacing);
        max-width: 0 !important;
        min-height: 0 !important;
    }
}

.section1 {
    display: flex;
    justify-content: space-between;
}

.profilePic {
    border-radius: 50%;
    width: 56px;
    height: 56px;
}


.name {
    margin-top: var(--l-spacing);
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
}

.des {
    font-size: var(--m-font-size);
    color: var(--black60);
}

.location {
    margin-top: var(--m-spacing);
    font-size: var(--m-font-size);
    display: flex;
    align-items: center;
}

.message {
    margin-top: var(--l-spacing);
    color: var(--black60);
    word-break: break-all;
    padding-right: var(--xs-spacing);
}

.locationName {
    margin-left: var(--s-spacing);
}

.testimonialImage {
    width: 50%;
    height: 50%;
    border-radius: var(--border-radius);

    @media (--phone) {
        width: 100% !important;
        height: 100% !important;
        z-index: -999999 !important;
    }
}

.imageCon {
    margin-top: var(--l-spacing);

    @media (--desktop) {
        display: flex;
        justify-content: center;
    }
}
