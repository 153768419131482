.paper {
    padding: 20px;
    @media (--desktop) {
        max-width:368px !important;
        padding:20px 40px;
    }
}

.modalBtn {
    min-width: 48%;
}

.title {
    font-weight: 500;
    font-size: var(--d-font-size);
    color: rgba(128, 128, 128, 0.75);
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: var(--xxl-spacing);
}
